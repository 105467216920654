// THIS FILE IS NO LONGER BEING USED. IT IS REPLACED BY ChatInit.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  FormErrorMessage,
  Flex,
  Button,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Reason from "./Reason";
import styles from "./styles.css";

function Booking() {
  // reasnons for the dental consultation
  const reasonList = [
    "Toothache",
    "Gum Evaluation",
    "Broken Tooth",
    "Overbite",
    "Straightening",
    "Other",
  ];
  const [reasons, setReasons] = useState({});
  const [spinner, setSpinner] = useState(false);

  // used to navigate to a different page
  const navigate = useNavigate();

  // form field validators
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [hasInsurance, setHasInsurance] = useState(false);

  // this is a catch-all for all form field values
  const [formFieldValues, setformFieldValues] = useState({
    patient_name: "",
    patient_phone: "", // needed for initial value for validator, as it expects a string
    patient_email: "", // needed for initial value for validator, as it expects a string
    patient_address: "",
    patient_reason: {},
    patient_description: "",
  });

  const changeReasons = (reason) => {
    setReasons({
      // since state is read-only, duplicate current state before mutation
      ...reasons,
      // for the changed reason, find its key, and flip it
      [reason]: reasons[reason] ? false : true,
    });
  };

  const handleFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // provide validation for phone or email, depending on which field was changed
    setformFieldValues((values) => ({ ...values, [name]: value }));

    // Perform validation after form field values updated
    setTimeout(() => {
      if (name === "patient_email") {
        setIsEmailValid(validator.isEmail(value));
      }
    }, 0);

    console.log(value);
  };

  const HomePageButton = () => {
    const navigate = useNavigate();
    return (
      <Button
        onClick={() => navigate("/")}
        ml={2}
        mt={2}
        borderRadius={"10px"}
        backgroundColor={"#0876DC"}
        color={"white"}
      >
        Go To Homepage
      </Button>
    );
  };

  // initialize reasons as an object with keys equal to each element in reasonList, and set them all to false
  useEffect(() => {
    let objFromArray = Object.fromEntries(reasonList.map((k) => [k, false]));
    setReasons(objFromArray);
  }, []);

  // once a reason attribue's boolean value is flipped, do this
  useEffect(() => {
    console.log(reasons);
    // scroll to other element, if other was flipped from false toe true
    if (reasons["Other"]) {
      const element = document.getElementById("other-input");
      element.scrollIntoView();
      element.focus();
    }
    setformFieldValues((values) => ({ ...values, patient_reason: reasons }));
  }, [reasons]);

  // Submit form, get Zoom url, and set it in Redux store
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    if (!isEmailValid) {
      setSpinner(false);
      return;
    }

    const trueReasons = Object.keys(formFieldValues.patient_reason).filter(
      (reason) => formFieldValues.patient_reason[reason]
    );

    let data = {
      ...formFieldValues,
      patient_reason: trueReasons,
      insurance_provider: hasInsurance,
    };

    const queryParams = new URLSearchParams(data).toString();

    navigate(`/waiting-room?${queryParams}`);
  };

  return (
    <>
      <Box id="back-button-booking-page" padding={"2% 2%"}>
        <HomePageButton />
      </Box>
      <Box
        id="consultation-form"
        w="100%"
        position="relative"
        backgroundColor={"white"}
        p={"0 10%"}
      >
        {/* TODO: add for validation for all form fields*/}
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel className="form-label">
              <Heading as="h3" size="md">
                Name
              </Heading>
            </FormLabel>
            <Input
              name="patient_name"
              className="form-input"
              type="text"
              placeholder="Full Name"
              required
              onChange={handleFormChange}
            />
          </FormControl>
          <FormControl isInvalid={!isValidPhone}>
            <FormLabel className="form-label">
              <Heading as="h3" size="md">
                Phone Number
              </Heading>
            </FormLabel>
            <Input
              name="patient_phone"
              className="form-input"
              type="tel"
              placeholder="000-000-0000"
              onChange={handleFormChange}
              required
            />
            {!isValidPhone && (
              <FormErrorMessage>Please enter a valid phone.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!isEmailValid}>
            <FormLabel className="form-label">
              <Heading as="h3" size="md">
                Email
              </Heading>
            </FormLabel>
            <Input
              name="patient_email"
              className="form-input"
              type="email"
              placeholder="johndoe@mail.com"
              onChange={handleFormChange}
              required
            />
            {!isEmailValid && (
              <FormErrorMessage>Please enter a valid email.</FormErrorMessage>
            )}
            {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
          </FormControl>
          <FormControl>
            <FormLabel className="form-label">
              <Heading as="h3" size="md">
                Address
              </Heading>
            </FormLabel>
            <Input
              name="patient_address"
              className="form-input"
              type="text"
              placeholder="Type here"
              onChange={handleFormChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel className="form-label">
              <Heading as="h3" size="md">
                I have insurance
              </Heading>
            </FormLabel>
            <Checkbox
              name="insurance_provider"
              isChecked={hasInsurance}
              onChange={(e) => setHasInsurance(e.target.checked)}
            >
              Yes
            </Checkbox>
          </FormControl>
          <FormControl></FormControl>
          <br />
          <section style={{ textAlign: "center" }}>
            <Heading as="h3" size="md" noOfLines={1} color={"#0876DC"}>
              Reason for Virtual Consultation
            </Heading>
            <p>(Select all that apply)</p>
            <Flex
              flexDir="row"
              flexWrap="wrap"
              justifyContent="space-between"
              mt={5}
            >
              {reasonList.map((reason) => (
                <Reason
                  key={reason}
                  reason={reason}
                  changeReasons={changeReasons}
                />
              ))}
            </Flex>
            <FormControl>
              <FormLabel className="form-label"></FormLabel>
              {/* TODO: change patient_description to something like "other_reason" in front and back ends */}
              <Input
                name="patient_description"
                id="other-input"
                className="form-input"
                onChange={handleFormChange}
                type="text"
                placeholder="Type here"
              />
            </FormControl>
            {!spinner && (
              <Button
                type="submit"
                colorScheme="teal"
                variant="outline"
                textAlign="center"
                borderRadius={"16px"}
                backgroundColor={"#0876DC"}
                color={"white"}
                m={"20px 0"}
                p={7}
                w={"50%"}
                disabled={!isEmailValid}
              >
                Next
              </Button>
            )}
            {spinner && <Spinner color="#E3BF00" size="xl" m={"20px 0"} />}
          </section>
        </form>
      </Box>
    </>
  );
}

export default Booking;
