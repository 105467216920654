import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Image,
  Link as ChakraLink,
  LinkProps,
  Text,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useEffect, useState } from "react";

function EmergencySection() {
  const [isLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
      hour12: false,
    })
  );

  const [alertMessage, setAlertMessage] = useState(null);

  const closeAlert = () => {
    setAlertMessage(null);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleTimeString("en-US", {
          timeZone: "America/New_York",
          hour12: false,
        })
      );
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    console.log(currentTime);
  }, [currentTime]);

  return (
    <Box
      id="schedule-section"
      className="main-page-section"
      bgGradient="primary"
      width={{ base: "unset", lg: "100%" }}
      sx={{ overflow: "hidden" }}
      height={{ base: "unset", md: 500 }}
    >
      {alertMessage && (
        <Alert
          status="info"
          variant="subtle"
          mt={4}
          position="fixed"
          top={14}
          left={0}
          right={0}
          borderRadius="md"
          zIndex={100}
        >
          <AlertIcon />
          {alertMessage}
          <Button
            ml={2}
            variant="ghost"
            size="sm"
            onClick={closeAlert}
            _focus={{ outline: "none" }}
          >
            Close
          </Button>
        </Alert>
      )}
      <Flex direction="row">
        <Box
          width={{ base: "unset", lg: "75%" }}
          display="flex"
          marginTop={{ base: "10px", lg: "100px" }}
          padding={{ base: "40px", lg: "unset" }}
          justifyContent={{ base: "center", lg: "flex-end" }}
          flexDirection="row"
        >
          <Box width={{ base: "100%", lg: "600px" }}>
            <Text
              pb="6px"
              fontSize={{ base: "50px", lg: "75px" }}
              sx={{
                color: "white",
                fontWeight: "700",
                fontFamily: "Open Sans Condensed",
              }}
            >
              Dental Emergency?
            </Text>
            <Box height={3} />
            <Text
              color="white"
              sx={{
                fontSize: 19.2,
                fontFamily: "Open Sans",
                fontWeight: "600",
                lineHeight: "35px",
              }}
            >
              Speak with Our AI Dentist Assistant about your emergency and get
              clear steps on what to do ! Our AI Dentist Assistant can book a
              virtual meeting with you or an in-person one as well.
            </Text>
            <Box height={3} />
            <ChakraLink as={ReactRouterLink} to="/bot">
              <Button
                bg="white"
                rounded="lg"
                variant="solid"
                color="#00C0EA"
                lineHeight="28px"
                fontWeight="700"
                whiteSpace={{ base: "pre-line" }}
                size="sm"
                height="unset"
                py="20px"
                mx={{ base: "10px", md: "unset" }}
                width={{ base: "90%", md: "300px" }}
                sx={{
                  fontSize: 19,
                  padding: "20px 30px",
                  textDecoration: "none",
                }}
                isLoading={isLoading}
              >
                Get started with your Virtual Triage!
              </Button>
            </ChakraLink>
          </Box>
        </Box>
        <Box display={{ base: "none", sm: "none", md: "none", xl: "block" }}>
          <Image
            margin={0}
            marginTop={-14}
            srcSet="
                ./assets/image-1/image-1.png 1x,
                ./assets/image-1/image-1@2x.png 2x,
                ./assets/image-1/image-1@3x.png 3x,
                ./assets/image-1/image-1@4x.png 4x,
            "
            alt="Woman with Painful Mouth"
          />
        </Box>
      </Flex>
    </Box>
  );
}

export default EmergencySection;
