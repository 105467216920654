import { useRouteError } from "react-router-dom";
import { Flex, Text, Box, Image, Button, Center } from "@chakra-ui/react";

export default function ErrorPage() {
	const error = useRouteError();
	console.error(error);

	return (
		<Flex>
			<Center>
				<Text>
					<h1>Oops!</h1>
					<p>Sorry, an unexpected error has occurred.</p>
					<p>
						<i>{error.statusText || error.message}</i>
					</p>
				</Text>
			</Center>
		</Flex>
	);
}
