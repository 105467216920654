import React, { useState, useEffect } from "react";
import { urls } from 'lib/api';
import {
  VStack,
  Button,
  Heading,
  useColorModeValue,
  Flex,
  Box,
} from "@chakra-ui/react";
import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

function ScheduleAppointment() {
  const bg = useColorModeValue("gray.100", "gray.700");
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    // Fetch availability status from the backend
    console.log("Fetching availability status...");
    fetch(urls.checkAvailable())
      .then((response) => response.json())
      .then((data) => {
        console.log("Availability status:", data.available);
        // Update the state based on the response
        setIsAvailable(data.available);
      })
      .catch((error) => {
        console.error("Error fetching availability:", error);
      });
  }, []);

  return (
    <Box backgroundColor={bg}>
      <Flex
        minHeight="100vh"
        backgroundColor={bg}
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing="6" alignItems="center" justifyContent="center">
          {!isAvailable && (
            <Link to="/booking">
              <Button leftIcon={<CalendarIcon />} colorScheme="blue" size="lg">
                Book Now (30 minutes)
              </Button>
            </Link>
          )}
          <Link to="https://cal.com/virtualdentist/15min">
            <Button leftIcon={<TimeIcon />} colorScheme="green" size="lg">
              Schedule for Later (15 minutes)
            </Button>
          </Link>
          <Link to="https://cal.com/virtualdentist/30min">
            <Button leftIcon={<TimeIcon />} colorScheme="green" size="lg">
              Schedule for Later (30 minutes)
            </Button>
          </Link>
        </VStack>
      </Flex>
    </Box>
  );
}

export default ScheduleAppointment;
