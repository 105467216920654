import { urls } from "lib/api";
import { useState } from "react";

const { useSelector } = require("react-redux");

const convertedOptions = (options) => {
  const optionsArray = options.map((optionString) => ({
    text: optionString,
    id: optionString,
  }));
  return optionsArray;
};

const convertedHistory = (history) => {
  // build up a history object.
  const historyArray = history.map((historyString) => {
    if (historyString.startsWith("User:")) {
      return {
        text: historyString.split("User: ")[1],
        isUser: true,
      };
    }
    if (historyString.startsWith("Bot: ")) {
      return {
        text: historyString.split("Bot: ")[1],
        isUser: false,
      };
    }
  });
  return historyArray;
};

function useBot() {
  const { name, email, file } = useSelector((state) => state.bot.patient);
  const patient = useSelector((state) => state.bot.patient);
  const init = useSelector((state) => state.bot.init);
  const [history, setHistory] = useState([]);
  const [options, setOptions] = useState([]);
  const [state, setState] = useState("start");
  const [previousState, setPreviousState] = useState(null);
  const [gptInputValue, setGptInputValue] = useState("");
  const [responseType, setResponseType] = useState("");
  const [loading, setLoading] = useState(false);

  const sendMessage = async (data) => {
    try {
      setLoading(true);
      // Include patient information in the request body
      const requestBody = {
        ...data,
        history,
        gpt_input_value: gptInputValue,
        image_url: file,
        patient_info: patient,
      };

      const result = await fetch(urls.chatbot(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const response = await result.json();

      setHistory(response.history);
      setOptions(response.options);
      setResponseType(response.response_type);
      setPreviousState(state);
      setState(response.state);
      setGptInputValue(response.gpt_input_value);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return [
    { name, email },
    init,
    sendMessage,
    convertedHistory([...history]),
    convertedOptions([...options]),
    responseType,
    state,
    previousState,
    gptInputValue,
    loading,
  ];
}

export default useBot;
