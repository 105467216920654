import { Box, Flex, Image, Text } from "@chakra-ui/react";

function HowItWorksSection() {
  return (
    <Box
      id="how-it-works-section"
      className="main-page-section"
      backgroundColor="#f7f7f7"
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <Flex
        direction={{ base: "column", lg: "row" }}
        px={{ base: "20px", lg: "unset" }}
        paddingTop={{ base: "20px", lg: 90 }}
        paddingBottom={{ base: "20px", lg: 90 }}
        justifyContent="center"
      >
        <Box width={{ base: "unset", lg: "339px" }}>
          <Box padding={{ base: "20px", lg: "unset" }} mb="20px">
            <Box
              marginLeft={{ base: "0px", md: "10px" }}
              marginTop={{ base: "20px", lg: "unset" }}
            >
              <Text
                fontSize={{ base: "44px", md: "35px" }}
                fontWeight="600"
                fontFamily="Open Sans"
                lineHeight="48px"
              >
                How it works
              </Text>
              <Box height={3} />
              <Box>
                <Text
                  fontSize={{ base: "16px" }}
                  lineHeight={{ base: "26.95px" }}
                  color="#2E3A40"
                  fontWeight={{ base: "500" }}
                >
                  Our AI-Powered dental services are available 24/7, providing
                  you with relevant advice and a video consultation with
                  licensed Ontario dentist.
                </Text>
                <Box height="20px" />
                <Text>
                  *Please note, our AI chatbot is not a licensed dental
                  professional, and is designed to help provide advice on
                  demand. It is not a substitute for seeing a dental
                  professional, and if you are experiencing a severe dental
                  emergency please call 911.
                </Text>
              </Box>
              <Box height="10px" />
              <Box>
                <Image
                  height={{ base: "unset", lg: "163px" }}
                  srcSet="
                ./assets/image-3/image-3.png 1x,
                ./assets/image-3/image-3@2x.png 2x,
                ./assets/image-3/image-3@3x.png 3x,
                ./assets/image-3/image-3@4x.png 4x,
            "
                  alt="Two overlapped devices, a iPad and a mac"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width={{ base: "100%", lg: "unset" }}>
          {[
            {
              title: "Intake Form",
              description: "Complete our patient intake form",
              icon: "./assets/icon/document-icon.svg",
            },
            {
              title: "Chat",
              description:
                "Chat with our AI Dentist Assistant about your problem",
              icon: "./assets/icon/chat.svg",
            },
          ].map(({ title, icon, description }) => (
            <Box
              width={{ base: "unset", lg: "339px" }}
              padding={{ base: "40px" }}
              mx={{ base: "20px" }}
              backgroundColor="#ffffff"
              borderRadius="4px"
              boxShadow="md"
              mb="4"
            >
              <Image width="52px" src={icon} alt="Woman with Painful Mouth" />
              <Box height="18px" />
              <Text
                fontSize={{ base: "30px" }}
                lineHeight={{ base: "42px" }}
                fontWeight={{ base: "600" }}
              >
                {title}
              </Text>
              <Box height="10px" />
              <Text
                fontSize={{ base: "16px" }}
                lineHeight={{ base: "26.95px" }}
                color="#2E3A40"
                fontWeight={{ base: "400" }}
              >
                {description}
              </Text>
            </Box>
          ))}
        </Box>
        <Box width={{ base: "100%", lg: "unset" }}>
          <Box height="40px" />
          {[
            {
              title: "Video Conference",
              description:
                "Meet your dentist online via the link sent to your email",
              icon: "./assets/icon/video-icon.svg",
            },
            {
              title: "In-Person",
              description: "Meet with a Dentist Near you (if necessary)",
              icon: "./assets/icon/person.svg",
            },
          ].map(({ title, icon, description }) => (
            <Box
              width={{ base: "unset", lg: "339px" }}
              padding={{ base: "40px" }}
              mx={{ base: "20px" }}
              backgroundColor="#ffffff"
              borderRadius="4px"
              boxShadow="md"
              mb="4"
            >
              <Image width="52px" src={icon} alt="Woman with Painful Mouth" />
              <Box height="18px" />
              <Text
                fontSize={{ base: "30px" }}
                lineHeight={{ base: "42px" }}
                fontWeight={{ base: "600" }}
              >
                {title}
              </Text>
              <Box height="10px" />
              <Text
                fontSize={{ base: "16px" }}
                lineHeight={{ base: "26.95px" }}
                color="#2E3A40"
                fontWeight={{ base: "400" }}
              >
                {description}
              </Text>
            </Box>
          ))}
        </Box>
      </Flex>
    </Box>
  );
}

export default HowItWorksSection;
