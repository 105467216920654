import React, { useState, useEffect } from "react";
import { Flex, Text, Box, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { urls } from 'lib/api'

function WaitingRoom() {
  const [isBooked, setIsBooked] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [bookingAttempted, setBookingAttempted] = useState(false);
  const [meetingURL, setMeetingUrl] = useState("");
  const navigate = useNavigate();
  const image_url = useSelector((state) => state.bot.patient.file);

  // get the meeting id
  const [searchParams] = useSearchParams();

  const patientName = searchParams.get("patient_name");
  const patientPhone = searchParams.get("patient_phone");
  const patientEmail = searchParams.get("patient_email");
  const patientAddress = searchParams.get("patient_address");
  const patientReason = searchParams.get("patient_reason");
  const patientDescription = searchParams.get("patient_description");
  const patientInsurance = searchParams.get("insurance_provider");
  const patientDOB = searchParams.get("dob");
  const messageList = searchParams.get("messageList");

  // isSuccessBookingPage is a flag that is set when the user has successfully booked a meeting
  // using cal.com
  const scheduled_meeting = searchParams.get("scheduled_meeting");

  const handleClick = () => {
    window.open(meetingURL, "_blank");
  };

  const handleBackClick = () => {
    navigate("/");
  };

  // Inside the WaitingRoom function component

  const createBooking = async () => {
    try {
      const response = await fetch(
        urls.bookingSuccess(),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            patient_name: patientName,
            patient_phone: patientPhone,
            patient_email: patientEmail,
            patient_address: patientAddress,
            patient_reason: patientReason,
            patient_description: patientDescription,
            insurance_provider: patientInsurance,
            dob: patientDOB,
            message_list: messageList,
            image_url,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create booking");
      }

      const responseJson = await response.json();
      setMeetingUrl(responseJson.data.meeting_url);
      setIsBooked(true);

      // Clear the search params from the URL without refreshing the page
      window.history.replaceState({}, document.title, window.location.pathname);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!bookingAttempted) {
      if (scheduled_meeting) {
        setIsScheduled(true);
        setBookingAttempted(true);
      } else if (!isBooked) {
        createBooking();
        setBookingAttempted(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduled_meeting]);

  // Add the history listener
  useEffect(() => {
    // Listen for changes to the current location.
    const unlisten = window.addEventListener("popstate", () => {
      // Redirect them to /booking page
      navigate("/chat-init", { replace: true });
    });

    // Clean up by stopping to listen when the component unmounts
    return () => {
      window.removeEventListener("popstate", unlisten);
    };
  }, [navigate]);

  return (
    <Box
      id="waiting-room"
      w="100%"
      position="relative"
      backgroundColor={"#e4edec"}
      pl={4}
      pr={4}
    >
      <Flex
        style={{ height: "100vh" }}
        position="relative"
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Image src="/assets/clock.svg" alt="Clock" />
        {isScheduled ? (
          <Text
            fontSize={"20px"}
            align="center"
            p={"20px 40px"}
            color={"#4e8077"}
            fontWeight={700}
          >
            Your meeting is scheduled. Please check your email for an invite.
          </Text>
        ) : isBooked ? (
          <>
            <Text
              fontSize={"20px"}
              align="center"
              p={"20px 40px"}
              color={"#4e8077"}
              fontWeight={700}
            >
              A dentist will be joining your Zoom call within the next 5 minutes
            </Text>
            <Button
              fontSize={"16px"}
              borderRadius={"16px"}
              backgroundColor={"#E3BF00"}
              color={"white"}
              fontWeight={700}
              w={"50%"}
              maxWidth={"300px"}
              p={7}
              onClick={handleClick}
            >
              Start Call
            </Button>
          </>
        ) : (
          <Text
            fontSize={"20px"}
            align="center"
            p={"20px 40px"}
            color={"#4e8077"}
            fontWeight={700}
          >
            Creating your booking...
          </Text>
        )}
        <Button
          fontSize={"16px"}
          borderRadius={"10px"}
          backgroundColor={"#E0BB00"}
          color={"white"}
          fontWeight={700}
          w={"50%"}
          mt={4}
          maxWidth={"300px"}
          onClick={handleBackClick}
        >
          Go To Homepage
        </Button>
      </Flex>
    </Box>
  );
}

export default WaitingRoom;
