import { Box, Button, Flex, FormLabel, Img, Text } from "@chakra-ui/react";
import React, { useState } from "react";
export function shrinkString(str, n = 30) {
  if (!str) return "";
  if (str.length > n) {
    return `${str.substring(0, n)}...`;
  }
  return str;
}

export const BotUpload = () => {
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [view, setView] = useState("normal"); //types: normal, expanded
  const [activeFileIndex, setActiveFileIndex] = useState(0);
  const [showHint, setShowHint] = useState(false);
  const [uploading, setUploading] = useState(false);

  function startUpload() {
    setUploading(true);
    setTimeout(() => {
      setUploading(false);
      setUploadedDocs([]);
      // send it to a server
      // send return url to backend
    }, 2000);
  }

  return (
    <>
      <div>
        <FormLabel
          for="image-upload"
          sx={{
            fontWeight: "bold",
            cursor: "pointer",
            p: "10px",
            display: "inline-block",
            bg: "fcfcfc",
            rounded: "sm",
          }}
          _hover={{ bg: "#ededed" }}
        >
          Upload Images
        </FormLabel>
        <input
          id="image-upload"
          type="file"
          style={{ display: "none" }}
          multiple
          onChange={(evt) => {
            setUploadedDocs([...evt.target.files]);
          }}
          accept="image/*"
        />
      </div>
      {uploadedDocs.length > 0 && (
        <Box
          position="fixed"
          bg="rgba(0,0,0, .5)"
          top={0}
          right={0}
          bottom={0}
          left={0}
          display="flex"
          flexDir="col"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            height="50%"
            width="800px"
            bg="white"
            rounded="md"
            border="1px solid gainsboro"
            padding="20px"
          >
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Text fontWeight="bold" fontSize="28px">
                  Upload Images
                </Text>
              </Box>
              <Box
                onClick={() => {
                  setUploadedDocs([]);
                }}
              >
                <Text
                  fontSize="28px"
                  color="red"
                  cursor="pointer"
                  fontWeight="bold"
                >
                  &times;
                </Text>
              </Box>
            </Box>
            <hr />
            {view === "normal" && (
              <Box mt="20px" height="400px" overflow="auto">
                <label
                  style={{
                    display: "inline-block",
                    width: "31.3%",
                    margin: "1%",
                  }}
                  for="more-input"
                >
                  <Flex
                    height="200px"
                    rounded="md"
                    cursor="pointer"
                    display="flex"
                    direction="column"
                    position="relative"
                    overflow="hidden"
                  >
                    <Flex
                      position="absolute"
                      top={0}
                      right={0}
                      bottom={0}
                      left={0}
                      justifyContent="center"
                      alignItems="center"
                      bg="transparent"
                      _hover={{ bg: "rgba(0,0,0,.2)" }}
                    >
                      <Box
                        bg="#fcfcfc"
                        border="1px solid gainsboro"
                        width="80px"
                        px="10px"
                        height="80px"
                        display="flex"
                        cursor="pointer"
                        transition=".15s ease-in-out"
                        rounded="md"
                        overflow="hidden"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          fontWeight="bold"
                          fontSize="40px"
                          lineHeight="20px"
                        >
                          +
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                  <Text title="Add" align="center" fontSize="12px">
                    Add more
                  </Text>
                  <input
                    id="more-input"
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(evt) => {
                      setUploadedDocs((ud) => [...ud, ...evt.target.files]);
                    }}
                    multiple
                  />
                </label>
                {uploadedDocs.map((file, index) => (
                  <Box
                    display="inline-block"
                    width="31.3%"
                    margin="1%"
                    onClick={() => {
                      setView("expanded");
                      setActiveFileIndex(index);
                    }}
                  >
                    <Flex
                      height="200px"
                      rounded="md"
                      cursor="pointer"
                      display="flex"
                      direction="column"
                      position="relative"
                      overflow="hidden"
                    >
                      <Img
                        src={URL.createObjectURL(file)}
                        height="200px"
                        objectFit="cover"
                      />
                      <Box
                        position="absolute"
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        bg="transparent"
                        _hover={{ bg: "rgba(0,0,0,.2)" }}
                      >
                        <Box
                          bg="#fcfcfc"
                          position="absolute"
                          //   width="40px"
                          px="10px"
                          height="20px"
                          display="flex"
                          justifyContent="center"
                          right="10px"
                          top="10px"
                          cursor="pointer"
                          transition=".15s ease-in-out"
                          rounded="md"
                          overflow="hidden"
                          _hover={{
                            color: "red",
                            width: "unset",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setUploadedDocs((ud) => [
                              ...[...ud].filter((f, ind) => ind !== index),
                            ]);
                          }}
                        >
                          <Text fontSize="12px" lineHeight="20px">
                            Remove &nbsp;&nbsp;
                          </Text>
                          <Text
                            fontWeight="bold"
                            fontSize="20px"
                            lineHeight="20px"
                          >
                            &times;
                          </Text>
                        </Box>
                      </Box>
                    </Flex>
                    <Text title={file.name} align="center" fontSize="12px">
                      {shrinkString(file.name, 20)}
                    </Text>
                  </Box>
                ))}
              </Box>
            )}
            {view === "expanded" && (
              <Box mt="20px" height="400px" overflow="hidden">
                <Flex>
                  <Box height="400px" overflow="auto" px="20px">
                    {uploadedDocs.map((file, index) => (
                      <Box
                        width="100px"
                        my="20px"
                        onClick={() => {
                          setActiveFileIndex(index);
                        }}
                      >
                        <Flex
                          height="100px"
                          width="100px"
                          cursor="pointer"
                          display="flex"
                          direction="column"
                          position="relative"
                          rounded="md"
                          overflow="hidden"
                        >
                          <Img
                            src={URL.createObjectURL(file)}
                            width="100px"
                            height="100px"
                            objectFit="cover"
                          />
                          <Box
                            position="absolute"
                            top={0}
                            right={0}
                            bottom={0}
                            left={0}
                            bg="transparent"
                            _hover={{ bg: "rgba(0,0,0,.2)" }}
                          ></Box>
                        </Flex>
                      </Box>
                    ))}
                  </Box>
                  <Flex
                    direction="col"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Box height="400px" width="400px" position="relative">
                      <Img
                        src={URL.createObjectURL(uploadedDocs[activeFileIndex])}
                        objectFit="cover"
                        height="100%"
                        width="100%"
                      />
                      <Box
                        position="absolute"
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        bg="transparent"
                        _hover={{ bg: "rgba(0,0,0,.2)" }}
                      >
                        <Box
                          bg="#fcfcfc"
                          position="absolute"
                          //   width="40px"
                          px="20px"
                          height="40px"
                          display="flex"
                          justifyContent="center"
                          right="-60px"
                          top="20px"
                          cursor="pointer"
                          onClick={() => {
                            setView("normal");
                          }}
                          transition=".15s ease-in-out"
                          onMouseEnter={() => setShowHint(true)}
                          onMouseLeave={() => setShowHint(false)}
                          _hover={{
                            color: "red",
                            width: "unset",
                            rounded: "full",
                          }}
                        >
                          {showHint && (
                            <Text fontSize="16px" lineHeight="40px">
                              Exit View &nbsp;&nbsp;&nbsp;
                            </Text>
                          )}
                          <Text
                            fontWeight="bold"
                            fontSize="40px"
                            lineHeight="40px"
                            bg="#fcfcfc"
                          >
                            &times;
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            )}
            <hr />
            <Flex justifyContent="end" my="10px">
              <Button
                disabled={uploading}
                backgroundColor={"#0876DC"}
                color={"white"}
                onClick={startUpload}
                _hover={{}}
              >
                {uploading ? "Please wait..." : "Confirm Upload"}
              </Button>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
};
