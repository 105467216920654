import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";

function Reason(props) {
  const [active, setActive] = useState(false);

  const handleClick = (e) => {
    setActive(active ? false : true);
    props.changeReasons(props.reason);
  };

  return (
    <>
      <Box
        className={`multi-select-box form-input ${
          active ? "active" : "inactive"
        }`}
        onClick={handleClick}
        mb={5}
        w="45%"
      >
        {/* get a non sarif font */}
        <Text>{props.reason}</Text>
      </Box>
    </>
  );
}

export default Reason;
