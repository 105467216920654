import { CSSReset, VStack } from "@chakra-ui/react";

import Nav from "./main_page/Nav";
import EmergencySection from "./main_page/EmergencySection";
import BypassSection from "./main_page/BypassSection";
import StepsSection from "./main_page/StepsSection";
import ContactSection from "./main_page/ContactSection";
import HowItWorksSection from "./main_page/HowItWorksSeciton";

function MainPage() {
  return (
    <>
      <CSSReset />
      {/* <NavigationMenu /> */}
      <Nav />
      <VStack gap={0}>
        <EmergencySection />
        <BypassSection />
        <HowItWorksSection />
        <ContactSection />
      </VStack>
    </>
  );
}

export default MainPage;
