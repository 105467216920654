const colors = {
  nav: {
    backgroundColor: "#2E3A40",
    button: {
      backgroundColor: "#00C0EA",
      color: "#FFFFFF",
    },
    link: {
      inactive: {
        color: "#FFFFFFF",
      },
      active: {
        color: "#70BE46",
      },
    },
  },
  section1: {
    backgroundColors: ["#FF0080 0%", "#7928CA 100%"],
  },
};

export default colors;
