import { configureStore } from "@reduxjs/toolkit";
import meetingURLReducer from "redux/reducers";
import botReducer from "./reducers/bot";

export default configureStore({
  reducer: {
    meetingURL: meetingURLReducer,
    bot: botReducer,
  },
});
