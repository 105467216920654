import { Box, Button, ButtonGroup, Flex, Image, Link } from "@chakra-ui/react";
import colors from "../../lib/colors";
function Nav({ notMain }) {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      padding="1rem"
      bg={colors.nav.backgroundColor}
      color="white"
      display={{ base: "none", md: "flex" }}
    >
      <Flex alignItems="center">
        <Image src="./assets/icon/Logo.svg" />
        <Box fontWeight="400" ml="20px" fontSize="17.6px">
          Virtualdentist.ai
        </Box>
      </Flex>
      <Flex
        paddingRight={{ base: "20px", md: "50px", lg: "100px", xl: "200px" }}
      >
        <ButtonGroup gap={4}>
          <Link
            href={notMain ? "/" : "#emergency-section"}
            color={colors.nav.link.active.color}
            variant="link"
            fontSize={17.6}
            lineHeight={2}
          >
            Home
          </Link>
          <Link
            href={notMain ? "/#how-it-works-section" : "#how-it-works-section"}
            color={colors.nav.link.inactive.color}
            variant="link"
            fontSize={17.6}
            lineHeight={2}
          >
            How it Works
          </Link>
          {/* {!notMain && (
            <Link
              color={colors.nav.link.inactive.color}
              variant="link"
              fontSize={17.6}
              lineHeight={2}
            >
              FAQ
            </Link>
          )} */}
          <Link
            href={notMain ? "/#contact-section" : "#contact-section"}
            color={colors.nav.link.inactive.color}
            variant="link"
            fontSize={17.6}
            lineHeight={2}
          >
            Contact
          </Link>
          {/* <Button
            as="a"
            href="https://cal.com/virtualdentist/30min"
            rel="noopener noreferrer"
            backgroundColor={colors.nav.button.backgroundColor}
            color={colors.nav.button.color}
            variant="solid"
            fontSize={12.8}
          >
            BOOK APPOINTMENT
          </Button> */}
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}

export default Nav;
