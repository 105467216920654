import { createSlice } from "@reduxjs/toolkit";

export const bot = createSlice({
  name: "bot",
  initialState: {
    init: "", // For whether or not user has completed the initial setup;
    patient: {
      name: "",
      phone: "",
      dob: "",
      address: "",
      email: "",
      insurance_provider: "None",
      file: "",
    },
  },
  reducers: {
    savePatient: (state, { payload }) => {
      state.init = true;
      state.patient = payload;
    },
  },
});

export const { savePatient } = bot.actions;

export default bot.reducer;
