import { Box, Button, Flex, Text } from "@chakra-ui/react";

function ContactSection() {
  return (
    <Box
      id="contact-section"
      className="main-page-section"
      backgroundColor="#00C0EA"
      color="white"
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <Flex
        direction="row"
        paddingTop={{ base: "20px", lg: "80px" }}
        paddingBottom={{ base: "20px", lg: "80px" }}
        justifyContent="center"
      >
        <Box width={{ base: "unset", lg: "55%" }}>
          <Box>
            <Text
              sx={{
                fontSize: 44,
                fontFamily: "Open Sans",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Have Questions?
            </Text>
            <Box height={3} />
            <Box marginLeft={{ base: "unset", lg: 10 }}>
              <Box marginBottom={5}>
                <Text
                  display={{ base: "none", lg: "block" }}
                  fontWeight={400}
                  fontSize={16}
                  textAlign="center"
                >
                  We are ready to help!
                </Text>
                <Box height="20px" />
                <Flex
                  justifyContent={{ base: "center", lg: "center" }}
                  mx={{ base: "10px", lg: "unset" }}
                >
                  {/* <Button
                    variant="solid"
                    color="#00C0EA"
                    sx={{ fontSize: 16 }}
                    display={{ base: "block", lg: "none" }}
                  >
                    Visit Our FAQ
                  </Button> */}
                  <Button
                    onClick={() => {
                      window.location.href = "mailto:admin@virtualdentist.ai";
                    }}
                    variant="solid"
                    color="#00C0EA"
                    sx={{ fontSize: 16 }}
                  >
                    Contact us
                  </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default ContactSection;
