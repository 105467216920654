import { Box, Flex, Image, Text } from "@chakra-ui/react";

function BypassSection() {
  return (
    <Box
      id="bypass-section"
      className="main-page-section"
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        paddingTop={{ base: "20px", lg: 90 }}
        paddingBottom={{ base: "20px", lg: 90 }}
      >
        <Box
          width={{ base: "unset", lg: "50%" }}
          display="flex"
          justifyContent={{ base: "center", lg: "flex-end" }}
          flexDirection="row"
        >
          <Box>
            <Image
              height={{ base: "unset", lg: "unset" }}
              srcSet="
                ./assets/image-2/image-2.png 1x,
                ./assets/image-2/image-2@2x.png 2x,
                ./assets/image-2/image-2@3x.png 3x,
                ./assets/image-2/image-2@4x.png 4x,
            "
              alt="Woman with Painful Mouth"
            />
          </Box>
        </Box>
        <Box width={{ base: "100%", lg: "700px" }}>
          <Box padding={{ base: "20px", lg: "unset" }}>
            <Text
              fontSize={{ base: "32px", md: "35px" }}
              fontWeight={{ base: "700", lg: "300" }}
              fontFamily="Open Sans"
              lineHeight="48px"
            >
              Bypass the hassle and get professional advice from the comfort of
              your home.
            </Text>
            <Box height={3} />
            <Box
              marginLeft={{ base: "0px", md: "10px" }}
              marginTop={{ base: "20px", lg: "unset" }}
            >
              {[
                {
                  icon: "./assets/icon/clock.svg",
                  text: "Available 7 days a week",
                },
                {
                  icon: "./assets/icon/calendar-check.svg",
                  text: "Same day appointments",
                },
                {
                  icon: "./assets/icon/plus.svg",
                  text: "Direct-to-pharmacy prescriptions",
                },
                {
                  icon: "./assets/icon/video-doc.svg",
                  text: "Virtual video consults",
                },
                {
                  icon: "./assets/icon/hand-plus.svg",
                  text: "Referral to a dentist near you",
                },
              ].map(({ icon, text }) => (
                <Flex>
                  <Box>
                    <Image
                      height={21}
                      src={icon}
                      alt="Woman with Painful Mouth"
                    />
                  </Box>
                  <Box marginLeft={8} marginBottom={5}>
                    <Text fontWeight={600} fontSize={19.2}>
                      {text}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default BypassSection;
