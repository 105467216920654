// PRODUCTION
const baseUrl = "https://www.virtualdentist.ai";

// DEVELOPMENT
// const baseUrl = "http://localhost:8000";

export const urls = {
  fileUpload: () => `${baseUrl}/uploadfile`,
  sendPatientData: () => `${baseUrl}/upload-patient-data`,
  chatbot: () => `${baseUrl}/chatbot`,
  checkAvailable: () => `${baseUrl}/check-available-now`,
  bookingSuccess: () => `${baseUrl}/booking-success`
};
