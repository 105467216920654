import React from "react";
import "./bot.css"; // Import your custom CSS styles here

const Loading = () => (
  <div className="chat-loading">
    <div className="spinner"></div>
  </div>
);

export default Loading;
