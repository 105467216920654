import React, { useState } from "react";
import {
  Flex,
  Text,
  Box,
  Image,
  HStack,
  Circle,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

const testimonials = [
  {
    image: "/assets/review-family-1.png",
    text: "Our schedules are so busy. Getting help for our smiles used to be so difficult. But being able to get help online has been a game changer for our busy family.",
    name: "Smith Family",
  },
  // {
  //   image: "/assets/review-family-1.png",
  //   text: "Your schedules are so busy. Getting help for our smiles used to be so difficult. But being able to get help online has been a game changer for our busy family.",
  //   name: "Jackson Family",
  // },
  // Add more testimonials as needed
];

function TestimonialSection() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleArrowClick = (direction) => {
    if (direction === "left") {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
      );
    } else {
      setActiveIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  return (
    <Box id="testimonial-page" w="100%" minH="100vh" pos="relative">
      <Box
        bgImage="url(/assets/dark-yellow-blob.png)"
        bgRepeat="no-repeat"
        bgPosition="top"
        bgSize="contain"
        minH="100px"
        h="auto"
        m={10}
      >
        <Flex
          h="100%"
          minH="100vh"
          pos="relative"
          align="center"
          justify="center"
          direction="column"
        >
          <Text as="h1" fontSize="2xl" fontWeight="bold">
            Clients
          </Text>
          <Box
            id="testimonials"
            bgRepeat="no-repeat"
            bgPosition="center"
            bgSize="cover"
            minH="400px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Image
              src={testimonials[activeIndex].image}
              alt="Family"
              mb={5}
              w="200px"
              h="200px"
            />
            <HStack spacing={2}>
              {testimonials.map((_, index) => (
                <Circle
                  key={index}
                  size="8px"
                  // bg={index === activeIndex ? "#52BC5D" : "gray.300"}
                />
              ))}
            </HStack>
            <HStack spacing={4}>
              {/* <IconButton
                bg="transparent"
                onClick={() => handleArrowClick("left")}
                icon={<ArrowBackIcon />}
                aria-label="Previous testimonial"
              />
              <IconButton
                bg="transparent"
                onClick={() => handleArrowClick("right")}
                icon={<ArrowForwardIcon />}
                aria-label="Next testimonial"
              /> */}
            </HStack>
          </Box>
          <Flex
            pos="relative"
            left="50%"
            transform="translateX(-50%)"
            align="center"
            justify="center"
            direction="column"
            pl={10}
            pr={10}
          >
            <Box display="flex" alignItems="center">
              {/* update image to have smaller size when screen gets smaller */}

              <Image
                alignSelf="start"
                src="/assets/quote-start.png"
                alt="Start quote"
                w={["15px", "15px", "20px", "30px"]}
              />
              <Box display="flex" alignItems="center">
                <Text fontSize="16px" pl={5} pr={5} textAlign="center">
                  {testimonials[activeIndex].text}
                </Text>
              </Box>

              <Image
                alignSelf="end"
                src="/assets/quote-end.png"
                alt="End quote"
                w={["15px", "15px", "20px", "30px"]}
              />
            </Box>
            <Text fontSize="16px" textAlign="center" fontWeight="bold">
              - {testimonials[activeIndex].name}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default TestimonialSection;
