import React from "react";
import { Provider } from "react-redux";
import store from "redux/store";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Router from "./routes";
import ReactDOM from "react-dom/client";
import "font.css";
import "styles.css";
import colors from "./lib/colors";

const customTheme = extendTheme({
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Open Sans, serif",
  },
  gradients: {
    primary: `linear-gradient(45deg, #5AAB2E 0%, #5AC2D9 100%)`,
  },
  style: {
    header: {
      fontFamily: "Open Sans Condensed",
    },
  },
  breakpoints: {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  },
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Router router={Router} />
    </ChakraProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
