import {
  Box,
  Button,
  Flex,
  FormControl,
  Select,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Image,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Loading from "./bot_page/Loading";
import { savePatient } from "redux/reducers/bot";
import { useDispatch } from "react-redux";
import Nav from "./main_page/Nav";
import colors from "lib/colors";
import { urls } from "lib/api";

const ACCEPTED_FILES = ["image/jpg", "image/jpeg", "image/png"];

const INSURANCE_PROVIDERS = [
  'NONE', 'OTHER', "CLAIM SECURE", "CO-OPRATORS", "COMPASS GROUP", "CORE BENEFITS", "COWAN INS", "DEJARDINS", "EMPIRE LIFE",
  "EQUITABLE LIFE", "GLOBAL BENEFITS", "GREAT WEST LIFE", "GREEN SHIELD", "GROUP HEALTH", "GROUP MEDICAL SERVICES",
  "GROUP SOURCE", "INDUSTRIAL ALLIANCE", "JOHNSTON GROUP", "LOCAL 175", "LOCAL 183", "LOCAL 34", "LOCAL 506",
  "MANION WIKINS", "MANULIFE", "MDM", "MEDME HEALTH", "NEXGEN TX", "OLICK", "ORLICK", "OTIP", "PBAS", "RBC",
  "RWM", "SOBOTEC", "SUN LIFE", "TELUS", "TUGO", "UFCW", "WORKERS UNITED CANADA", "CANADIAN DENTAL CARE PLAN",
  'BLUE CROSS', 'BENEVA', 'BLENDABLE', 'BPA', 'CANADA LIFE', "CHAMBER'S OF COMMERCE INS", "CINUP", 'AETNA', 'BLUE CROSS BLUE SHIELD', 'UNITED HEALTH CARE', 'CIGNA',
  'ODSP', 'HSO', "OW", 'BLUE CROSS R', 'ADJUDI CARE', 'ALLIANZ', 'ALLSTATE', 'BENEFITS TRUST'
];


function ChatInit() {
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [doc, setDoc] = useState(null);

  function registerUploadedFiles(files) {
    if (!ACCEPTED_FILES.includes(files[0].type)) {
      return null;
    }
    setDoc(files[0]);
  }

  const [formFieldValues, setformFieldValues] = useState({
    patient_name: "",
    patient_phone: "",
    patient_email: "",
    patient_address: "",
    insurance_provider: "None",
    dob: "",
  });

  const handleFormChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setformFieldValues((values) => ({ ...values, [name]: value }));
    setDateError(false);
    if (name === "patient_email") {
      setIsEmailValid(validator.isEmail(value));
    }
  };

  const HomePageButton = () => {
    const navigate = useNavigate();
    return (
      <Button
        onClick={() => navigate("/")}
        ml={2}
        mt={2}
        borderRadius={"10px"}
        backgroundColor={"#0876DC"}
        color={"white"}
      >
        Go To Homepage
      </Button>
    );
  };

  const dispatch = useDispatch();
  const [dateError, setDateError] = useState(null);

  const handleSubmit = async (e) => {
    setDateError(null);
    e.preventDefault();
    setSpinner(true);

    // Check for Correct Email Format
    if (!isEmailValid) {
      setSpinner(false);
      return;
    }

    // Check for Correct Date of Birth Format
    let dobValue = formFieldValues.dob;
    if (!dobValue) {
      setDateError("Enter Date of birth");
      setSpinner(false);
      return;
    }
    if (
      !/^(0?[1-9]|1[0-2])([-/]?(0?[1-9]|[12][0-9]|3[01]))?([-/]?((19|20)\d{2}))?$/.test(
        dobValue
      )
    ) {
      setDateError("Date format is incorrect");
      setSpinner(false);
      return;
    }

    // Format the date if entered without dashes
    if (/^\d{8}$/.test(dobValue)) {
      dobValue = `${dobValue.substring(0, 2)}-${dobValue.substring(
        2,
        4
      )}-${dobValue.substring(4)}`;
      formFieldValues.dob = dobValue;
    }

    // Upload File if Exists
    let file = null;
    try {
      // Upload File if Exists
      if (doc) {
        console.log("Uploading file to server...");
        console.log(urls.fileUpload());
        const formData = new FormData();
        formData.append(`file`, doc);
        const fileResult = await fetch(urls.fileUpload(), {
          method: "POST",
          body: formData,
        });
        console.log("got file result");
        const fileJson = await fileResult.json();
        if (!fileResult.ok) {
          throw new Error(fileJson);
        }
        file = fileJson?.uploaded_url;
      }
      console.log("Uploading user data to server...");

      // Upload User Data Record
      const fileResult = await fetch(urls.sendPatientData(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formFieldValues.patient_name,
          phone: formFieldValues.patient_phone,
          dob: formFieldValues.dob,
          address: formFieldValues.patient_address,
          email: formFieldValues.patient_email,
          insurance_provider: formFieldValues.insurance_provider,
          file,
        }),
      });
      if (!fileResult.ok) {
        throw new Error("User data upload not successful!");
      }
      console.log("User data uploaded successfully!");

      // Update Stae of Patient Data
      dispatch(
        savePatient({
          name: formFieldValues.patient_name,
          phone: formFieldValues.patient_phone,
          dob: formFieldValues.dob,
          address: formFieldValues.patient_address,
          email: formFieldValues.patient_email,
          insurance_provider: formFieldValues.insurance_provider,
          file,
        })
      );
      navigate("/bot");
    } catch (e) {
      // console.log("Something went wrong!");
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Box overflow="hidden" height="100vh">
      <Nav notMain />
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        padding="1rem"
        bg={colors.nav.backgroundColor}
        color="white"
        display={{ base: "flex", md: "none" }}
      >
        <Flex alignItems="center">
          <Image src="./assets/icon/Logo.svg" width="30px" />
          <Box fontWeight="400" ml="20px" fontSize="17.6px">
            Virtualdentist.ai
          </Box>
        </Flex>
        <Flex>
          <Button
            as="a"
            href="/"
            rel="noopener noreferrer"
            backgroundColor="#70BE46"
            color="white"
            variant="solid"
            fontSize={16.8}
          >
            Home
          </Button>
        </Flex>
      </Flex>
      {/* WHERE THE FORMS FIELDS ARE */}
      <Box overflowY="auto" height="90vh">
        <Box
          id="consultation-form"
          w="100%"
          position="relative"
          backgroundColor={"white"}
          p={"2% 10%"}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel className="form-label">
                <Heading as="h3" size="md">
                  Name <label style={{ color: "red" }}>*</label>
                </Heading>
              </FormLabel>
              <Input
                name="patient_name"
                className="form-input"
                type="text"
                placeholder="Full Name"
                required
                onChange={handleFormChange}
              />
            </FormControl>
            <FormControl isInvalid={!isValidPhone}>
              <FormLabel className="form-label">
                <Heading as="h3" size="md">
                  Phone Number <label style={{ color: "red" }}>*</label>
                </Heading>
              </FormLabel>
              <Input
                name="patient_phone"
                className="form-input"
                type="tel"
                placeholder="000-000-0000"
                onChange={handleFormChange}
                required
              />
              {!isValidPhone && (
                <FormErrorMessage>Please enter a valid phone.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!isEmailValid}>
              <FormLabel className="form-label">
                <Heading as="h3" size="md">
                  Email <label style={{ color: "red" }}>*</label>
                </Heading>
              </FormLabel>
              <Input
                name="patient_email"
                className="form-input"
                type="email"
                placeholder="johndoe@mail.com"
                onChange={handleFormChange}
                required
              />
              {!isEmailValid && (
                <FormErrorMessage>Please enter a valid email.</FormErrorMessage>
              )}
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
            <FormControl>
              <FormLabel className="form-label">
                <Heading as="h3" size="md">
                  Address
                </Heading>
              </FormLabel>
              <Input
                name="patient_address"
                className="form-input"
                type="text"
                placeholder="Type here"
                onChange={handleFormChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel className="form-label">
                <Heading as="h3" size="md">
                  Date of Birth <label style={{ color: "red" }}>*</label>
                </Heading>
              </FormLabel>
              <Input
                name="dob"
                className="form-input"
                type="text"
                placeholder="MM-DD-YYYY"
                onChange={handleFormChange}
              />
            </FormControl>
            {/* INSURNACE PROVIDER FIELD */}
            <FormControl>
              <FormLabel className="form-label">
                <Heading as="h3" size="md">
                  Insurance Provider
                </Heading>
              </FormLabel>
              <Select
                name="insurance_provider"
                placeholder="Select provider"
                onChange={handleFormChange}
                value={formFieldValues.insurance_provider}
              >
                {INSURANCE_PROVIDERS.map(provider => (
                  <option key={provider} value={provider}>{provider}</option>
                ))}
              </Select>
            </FormControl>
            {/* UPLOAD FILE FIELD */}
            <FormControl>
              <FormLabel
                className="form-label"
                htmlFor="upload_image"
                cursor="pointer"
              >
                <Heading as="h3" size="md">
                  Upload Image{" "}
                  <Text fontStyle="italic" fontSize={12}>
                    (Click to upload)
                  </Text>
                </Heading>
              </FormLabel>
              <Input
                accept=".jpg,.jpeg,.png"
                id="upload_image"
                display="none"
                name="image_upload"
                className="form-input"
                type="file"
                placeholder="Upload Image"
                onChange={(evt) => registerUploadedFiles(evt.target.files)}
              />
            </FormControl>
            {doc && (
              <Box>
                <Box>
                  <Image
                    src={URL.createObjectURL(doc)}
                    width="300px"
                    height="300px"
                  />
                </Box>
                <Button
                  fontSize={12}
                  h="30px"
                  onClick={() => {
                    setDoc(null);
                  }}
                >
                  <Text display={{ base: "none", md: "inline" }}>Remove</Text>
                  <Text
                    fontSize="24px"
                    display={{ base: "inline", md: "none" }}
                  >
                    &times;
                  </Text>
                </Button>
              </Box>
            )}
            {dateError && (
              <FormControl>
                <FormLabel className="form-label" style={{ color: "red" }}>
                  <Heading as="h3" size="md">
                    {dateError}
                  </Heading>
                </FormLabel>
              </FormControl>
            )}
            {!spinner && (
              <Button
                type="submit"
                colorScheme="teal"
                variant="outline"
                textAlign="center"
                borderRadius={"16px"}
                backgroundColor={"#0876DC"}
                color={"white"}
                m={"20px 0"}
                p={7}
                disabled={!isEmailValid}
              >
                Next
              </Button>
            )}
          </form>
        </Box>
        {spinner && <Loading />}
      </Box>
    </Box>
  );
}

export default ChatInit;
