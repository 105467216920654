import React from "react";
import MainPage from "components/MainPage";
import Testimonials from "components/main_page/TestimonialSection";
import Booking from "components/booking_page";
import WaitingRoom from "components/waiting_room";
import ErrorPage from "components/error_page";
import ScheduleAppointment from "components/schedule_appointment_page/ScheduleAppointmentPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Bot from "components/bot_page/Bot";
import ChatInit from "components/ChatInit";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/schedule-appointment" element={<ScheduleAppointment />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/waiting-room" element={<WaitingRoom />} />
        <Route path="/bot" element={<Bot />} />
        <Route path="/chat-init" element={<ChatInit />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
