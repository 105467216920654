import { Box, Button, Flex, Image } from "@chakra-ui/react";
import useBot from "hooks/useBot";
import { useEffect, useRef, useState } from "react";
import "./bot.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { debounce } from "lodash";
import Nav from "components/main_page/Nav";
import colors from "lib/colors";
import { BotUpload } from "./BotUpload";

const ChatBot = () => {
  const navigate = useNavigate();
  const { patient } = useSelector((state) => state.bot);
  console.log("patient: ", patient);

  const [userInput, setUserInput] = useState("");
  const [error, setError] = useState("");
  const [lastBotMessage, setLastBotMessage] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);

  const messagesRef = useRef(null);
  const hasRedirected = useRef(false);

  const scrollToBottom = () => {
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
  };

  const handleRedirect = () => {
    if (hasRedirected.current) return;

    console.log("Redirecting... to waiting room");
    const mandatoryParams = {
      patient_name: patient.name,
      patient_email: patient.email,
      patient_phone: patient.phone,
      patient_address: patient.address,
      insurance_provider: patient.insurance_provider,
      dob: patient.dob,
      patient_reason: "Dental Checkup",
      patient_description: "Dental Checkup",
      messageList: JSON.stringify(messageList),
      patient_upload_url: patient.file,
    };

    const queryParams = new URLSearchParams(mandatoryParams).toString();
    navigate(`/waiting-room?${queryParams}`);

    hasRedirected.current = true;
  };

  const debouncedHandleRedirect = debounce(handleRedirect, 300);

  const [
    { name, email },
    init,
    send,
    messageList,
    options,
    responseType,
    state,
    previous_state,
    _,
    loading,
  ] = useBot();

  useEffect(() => {
    if (messageList.length > 0) {
      const lastMessage = messageList[messageList.length - 1];
      if (!lastMessage.isUser) {
        // if the last message is from the bot
        setLastBotMessage(lastMessage.text);
      }
      scrollToBottom();
    }
  }, [messageList]);

  useEffect(() => {
    checkScrollPosition();
  }, [showScrollButton]);

  useEffect(() => {
    if (!init) {
      navigate("/chat-init");
      return;
    }
    if (!messageList.length) {
      send({
        current_state: "start",
        previous_state: null,
        answer: "",
      });
    }
  }, [init, navigate, messageList, send]);

  const checkScrollPosition = () => {
    if (messagesRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
      const scrollOffset = scrollHeight - scrollTop - clientHeight;
      setShowScrollButton(scrollOffset > 100); // Show button if not close to the bottom
    }
  };

  return (
    <Box sx={{ height: "100vh", overflow: "hidden" }}>
      <Nav notMain />
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        padding="1rem"
        bg={colors.nav.backgroundColor}
        color="white"
        display={{ base: "flex", md: "none" }}
      >
        <Flex alignItems="center">
          <Image src="./assets/icon/Logo.svg" width="30px" />
          <Box fontWeight="400" ml="20px" fontSize="17.6px">
            Virtualdentist.ai
          </Box>
        </Flex>
        <Flex
        // paddingRight={{ base: "20px", md: "50px", lg: "100px", xl: "200px" }}
        >
          <Button
            as="a"
            href="/"
            rel="noopener noreferrer"
            backgroundColor="#70BE46"
            color="white"
            variant="solid"
            fontSize={16.8}
          >
            Home
          </Button>
        </Flex>
      </Flex>
      <Flex display={{ base: "block", md: "flex" }} gap={3}>
        <Box flex={{ base: 1 }}>
          <div className="chat-container">
            <div className="chat-messages" ref={messagesRef}>
              {messageList.map(
                ({ text, isUser }, index) =>
                  text && (
                    <div
                      key={`message-${index}`}
                      className={`message ${isUser ? "right" : "left"}`}
                    >
                      {!isUser && <div className="participant-tag">Dr.</div>}
                      <div
                        dangerouslySetInnerHTML={{ __html: text }}
                        className={`message-bubble ${
                          isUser ? "right" : "left"
                        }`}
                      />
                      {isUser && (
                        <div className="participant-tag user">
                          {patient.name[0].toUpperCase()}
                          {patient.name.split(" ")[1]
                            ? patient.name.split(" ")[1][0].toUpperCase()
                            : patient.name[1]}
                        </div>
                      )}
                    </div>
                  )
              )}
              {loading && (
                <div className="message left">
                  <div className="participant-tag">Dr.</div>
                  <div
                    className="message-bubble left"
                    style={{
                      minHeight: 28,
                      minWidth: 45,
                      border: "1px solid #ccc",
                    }}
                  >
                    <div className="loading"></div>
                    <Box
                      sx={{
                        ml: "34px",
                        fontSize: 13,
                      }}
                    >
                      {" "}
                      Thinking, please wait a minute...
                    </Box>
                  </div>
                  {/* <div className="participant-tag">Dentist</div> */}
                </div>
              )}
            </div>
            {showScrollButton && (
              <button className="scroll-button" onClick={scrollToBottom}>
                Scroll Down
              </button>
            )}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
              flexDirection={{
                base: "column",
                md: "row",
              }}
            >
              {responseType === "multiple_choice" && (
                <Box
                minW={{ base: "100%", md: "300px" }}
                maxH={{ base: 200 }}
                mt="20px"
                >
                  {options.length > 0 && "Choose one of these to continue:"}
                </Box>
              )}
              {responseType === "multiple_choice" && !loading && (
                <Box className="random-options">
                  {options.map(({ text }, index) => (
                    <Button
                      key={`options-${index}`}
                      onClick={() => {
                        if (text.toLowerCase() === "restart") {
                          send({
                            current_state: "start",
                            previous_state: null,
                            answer: "",
                            history: [],
                          });
                        } else if (
                          text.toLowerCase() === "schedule meeting later" ||
                          text.toLowerCase() === "meeting later"
                        ) {
                          const conversationString = messageList
                            .map(({ text, isUser }) => {
                              const prefix = isUser ? "User:" : "Bot:";
                              return `${prefix} ${text}`;
                            })
                            .join("<br/>");
                          console.log(patient.file);
                          console.log(encodeURI(patient.file));
                          console.log(encodeURIComponent(patient.file));
                          const calComLink = `https://cal.com/virtualdentist/30min?name=${
                            patient.name
                          }&email=${patient.email}&phone=${encodeURIComponent(
                            "+1" + patient.phone
                          )}&insurance-provider=${
                            patient.insurance_provider
                          }&image-url=${encodeURIComponent(patient.file)}`; // Replace with your Cal.com link
                          console.log("Cal.com link: ", calComLink);
                          window.location.href = calComLink;
                        } else {
                          send({
                            current_state: state,
                            previous_state: previous_state,
                            answer: text,
                            name: name,
                            email: email,
                          });
                        }
                      }}
                      className="blue-button"
                    >
                      {text}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
            {responseType === "text" && !loading && (
              <>
                <div className="user-input">
                  <input
                    type="text"
                    placeholder="Type your message..."
                    onChange={(e) => {
                      setError("");
                      setUserInput(e.target.value);
                    }}
                    value={userInput}
                  />
                  <button
                    onClick={() => {
                      if (userInput.length <= 3) {
                        setError("Text too short");
                        return;
                      }
                      send({
                        current_state: state,
                        previous_state: previous_state,
                        answer: userInput,
                      });
                    }}
                    className="send-button"
                  >
                    Send
                  </button>
                </div>
                {error && <div style={{ color: "red" }}>{error}</div>}
              </>
            )}
            {/* IMAGE UPLOAD */}
            {responseType === "image" && <BotUpload />}
          </div>
        </Box>
      </Flex>
    </Box>
  );
};

export default ChatBot;
